<template>
  <figure class="icon">
    <svg width="191" height="191" viewBox="0 0 191 191" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="95.5" cy="95.5" r="95" fill="white" stroke="#D8D8D8" stroke-dasharray="5 5" />
      <rect x="95" y="60" width="1" height="70" rx="0.5" fill="#D8D8D8" />
      <rect x="131" y="94" width="1" height="70" rx="0.5" transform="rotate(90 131 94)" fill="#D8D8D8" />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "CourseBuyPlusIcon",
};
</script>
