<template>
  <main class="main education">
    <div class="container education__container">
      <section class="education__section">
        <h1 class="education__title">Текущие курсы</h1>
        <div class="education__list">
          <template v-if="courses">
            <CourseProgressComponent v-for="(course, i) in courses" :course="course" :key="i" />
          </template>
          <CourseBuyNewComponent />
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import CourseProgressComponent from "components/CourseProgressComponent.vue";
import CourseBuyNewComponent from "./components/CourseBuyNewComponent.vue";
import ACCOUNT_EDUCATION_PAGE from "gql/pages/AccountEducation.graphql";
// import CourseComponent from "components/CourseComponent.vue";

export default {
  name: "AccountEducation",
  async asyncData({ store, apollo }) {
    await apollo.defaultClient
      .query({
        query: ACCOUNT_EDUCATION_PAGE,
        context: {
          headers: {
            Authorization: "Bearer " + store.state.apollo_token,
          },
        },
      })
      .then(({ data }) => {
        store.state.tmp.courses = data.my_courses;
        store.state.info = data.feedback;
      })
      .catch(() => {
        store.state.tmp.haveError = true;
      });
  },
  computed: {
    courses() {
      return this.$store.state.tmp.courses;
    },
  },
  mounted() {
    if (this.$store.state.tmp.haveError) {
      this.$notify({
        title: "Ошибка",
        text: "Во время загрузки данных произошла ошибка, попробуйте перезагрузить страницу",
        duration: 8000,
        speed: 200,
        type: "error",
      });
    }
  },
  components: { CourseBuyNewComponent, CourseProgressComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/education.styl"
</style>
