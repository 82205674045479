<template>
  <router-link :to="{ name: 'home', hash: '#catalog' }" class="buy-new">
    <div class="buy-new__icon">
      <CourseBuyPlusIcon />
    </div>
    <h2 class="buy-new__title">Приобрести <br />новый курс</h2>
    <span class="buy-new__subtitle">Для приобретения нового курса<br /><ins>нажмите сюда</ins></span>
  </router-link>
</template>

<script>
import CourseBuyPlusIcon from "./svg/PlusIcon.vue";

export default {
  name: "CourseBuyNewComponent",
  components: { CourseBuyPlusIcon },
};
</script>

<style lang="stylus">
.buy-new {
  background: var(--white);
  border: 1px dashed var(--border_color);
  box-sizing: border-box;
  border-radius: var(--radius);
  padding 16px
  display grid
  grid-template-rows 1fr auto auto
  grid-gap 8px
  align-items end

  &:hover {
    border-color var(--border_color)
  }

  &__icon {
    width 100%
    height 100%
    box-sizing: border-box;
    position relative
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      max-width 191px
      max-height 191px
      width 100%
      height 100%

      svg {
        width 100%
        height 100%
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 130%;
    color: var(--black);
    margin 0
  }

  &__subtitle {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 140%;
    color: var(--gray_dark);

    ins {
      color var(--main_color)
      text-decoration: underline;
      text-decoration-color var(--border_color)
    }
  }

  &__icon {
    height 100%
    width 100%
  }
}
</style>
